import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { AppUser, AppUserDoc, AppUsersDict } from "../types/users";
import db, { functions } from "../types/firebase";
import { httpsCallable } from "firebase/functions";

interface CreateUserResult {
  data: {
    status: string;
    error?: string;
    id?: string;
    user?: AppUser;
  };
}

interface HttpRequestResult {
  data: {
    status: string;
    error?: string;
  };
}

export const isUserRegistered = async (email: string) => {
  try {
    const docRef = query(collection(db, "users"), where("email", "==", email.toLowerCase()));
    const docSnap = await getDocs(docRef);
    console.log("users found: ", docSnap.docs);
    return docSnap.docs.length > 0;
  } catch (e) {
    console.log("Error getting  document:", e);
    return false;
  }
};

export const loadAppUser = async (userId: string) => {
  if (!userId) return null;
  const userQuery = query(collection(db, "users"), where("id", "==", userId));
  const userSnapshot = await getDocs(userQuery);
  if (!userSnapshot.empty) {
    return { id: userSnapshot.docs[0].id, user: userSnapshot.docs[0].data() as AppUser };
  } else {
    return null;
  }
};

export const loadAppUsers = async (user: AppUser | null | undefined) => {
  console.log("loading users:", user);
  if (!user) {
    return {};
  }

  let usersRef;
  let usersSnapshot;
  const usersDict: AppUsersDict = {};

  // If the user is not an admin, return only the user's own data
  if (!user.isAdmin) {
    usersRef = query(collection(db, "users"), where("id", "==", user.id));
  } else {
    // super admin --> Load all users
    usersRef = collection(db, "users");
  }

  usersSnapshot = await getDocs(usersRef);
  usersSnapshot.forEach((doc) => {
    const appUser = doc.data() as AppUser;
    if (!appUser.availableTests) {
      appUser.availableTests = 0;
    }
    usersDict[doc.id] = appUser;
  });
  return usersDict;
};

export const existsAppUserEMail = async (email: string) => {
  console.log("Checking for user with email: " + email);
  const q = query(collection(db, "users"), where("email", "==", email));
  const querySnapshot = await getDocs(q);
  console.log("email exists: " + !querySnapshot.empty);
  return !querySnapshot.empty;
};

export const storeAppUser = async (
  user: AppUser | null | undefined,
  userDoc: AppUserDoc | null,
  addIfNew: boolean
) => {
  if (!(userDoc && userDoc.user)) {
    return null;
  }

  console.log("storing user: ", userDoc);

  let userSnapshot = null;

  if (userDoc.id) {
    const docRef = doc(db, "users", userDoc.id);
    userSnapshot = await getDoc(docRef);
    // Update the document
    if (userSnapshot) {
      userDoc.user.updatedAt = Timestamp.now();
      userDoc.user.updatedBy = user ? user.id : "anonymous";
      const usr = { ...userDoc.user };
      if (usr.results) {
        delete usr.results;
      }
      await setDoc(docRef, usr);
      console.log("updated user:", userDoc.id);
    }
  } else if (addIfNew) {
    userDoc.user.createdAt = Timestamp.now();
    userDoc.user.createdBy = user ? user.id : "anonymous";
    userDoc.id = (await addDoc(collection(db, "users"), userDoc.user)).id;
    console.log("created user:", userDoc.id);
  } else {
    return null;
  }
  return { ...userDoc };
};

export const disableUser = async (
  user: AppUser | null | undefined,
  userId: string
): Promise<string> => {
  if (!userId) return "no-user";
  if (!user || !user.isAdmin) {
    return "insufficient-permissions";
  }

  const fncDisableUser = httpsCallable(functions, "api/users/disableUser");

  try {
    const response = (await fncDisableUser({ userId })) as CreateUserResult;
    console.log("delete user response: ", response);
    if (response.data.status === "OK") {
      return "success";
    } else return "error";
  } catch (err) {
    return "error";
  }
};

export const enableUser = async (
  user: AppUser | null | undefined,
  userId: string
): Promise<string> => {
  if (!userId) return "no-user";
  if (!user || !user.isAdmin) {
    return "insufficient-permissions";
  }

  const fncEnableUser = httpsCallable(functions, "api/users/enableUser");

  try {
    const response = (await fncEnableUser({ userId })) as CreateUserResult;
    console.log("delete user response: ", response);
    if (response.data.status === "OK") {
      return "success";
    } else return "error";
  } catch (err) {
    return "error";
  }
};

export const createUser = async (user: AppUser | null | undefined, newUser: AppUser) => {
  console.log("creating new user: ", newUser);
  const fncCreateUser = httpsCallable(functions, "api/v1/users/createUser");
  try {
    const response = (await fncCreateUser({ user: newUser })) as CreateUserResult;
    console.log("createUser response: ", response);
    console.log("response: ", response);
    if (response.data.status === "OK" && response.data.id) {
      newUser.id = response.data.id;
      const createdUser = await storeAppUser(user, { id: "", user: newUser }, true);
      if (createdUser) return { status: "OK", id: createdUser.id, user: createdUser.user };
      else return { status: "ERROR", error: "User could not be created" };
    } else return { status: "ERROR", error: response.data.error };
  } catch (err) {
    return { status: "ERROR", error: err };
  }
};

export const registerUser = async (user: AppUser) => {
  try {
    console.log("registering user...");
    const fncRegisterUser = httpsCallable(functions, "api/v1/users/registerUser");
    const result = (await fncRegisterUser({
      user,
    })) as CreateUserResult;
    console.log("register user result result: ", result.data);

    if (result.data.status === "OK") {
      const userId = result.data.id;
      return userId;
    } else {
      console.log("registering user failed...");
      return null;
    }
  } catch (e) {
    console.error("Error adding document: ", e);
    return null;
  }
};

export const sendLoginCredentials = async (user: AppUser) => {
  console.log("user: ", user);

  const fncSendLoginCredentials = httpsCallable(functions, "api/v1/users/sendLoginCredentials");
  try {
    const response = (await fncSendLoginCredentials({
      userId: user.id,
    })) as HttpRequestResult;
    console.log("sendLoginCredentials response: ", response);
    return response.data;
  } catch (err) {
    return { status: "ERROR", error: err };
  }
};

export const updateUser = async (userId: string, user: { [key: string]: any }) => {
  try {
    const docRef = doc(db, "users", userId);
    await updateDoc(docRef, user);
    console.log("Document updated with ID: " + docRef.id);
    return userId;
  } catch (e) {
    console.error("Error updating document: ", e);
    return "";
  }
};

export const deleteUser = async (userId: string) => {
  if (!userId) return "no-user";

  const fncDeleteUser = httpsCallable(functions, "api/v1/users/deleteUser");

  try {
    const response = (await fncDeleteUser({ userId })) as HttpRequestResult;
    console.log("delete user response: ", response);
    if (response.data.status === "OK") {
      return "success";
    } else return "error";
  } catch (err) {
    return "error";
  }
};
