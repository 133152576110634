import { ReactNode } from "react";
import { useEffect, useState, createContext } from "react";
import { app, functions } from "../types/firebase";
import {
  getAuth,
  onAuthStateChanged,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { AppUser, AppUserDoc, AuthUser } from "../types/users";
import { loadAppUser } from "../data-functions/users-api";
import { Language } from "../types/languages";
import { isUserRegistered } from "../data-functions/auth-api";
import { httpsCallable } from "firebase/functions";
import env from "../env-config";
import { ResponseData } from "../types/commons";

interface AuthProviderProps {
  children: ReactNode;
}

interface ContextType {
  currentUser: AuthUser | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<AuthUser | null>>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  sendSignInLinkToEmail: (
    email: string,
    language: Language,
    checkIfRegistered: boolean
  ) => Promise<boolean>;
  isSignInWithEmail: (url: string) => boolean;
  signInWithEmail: (email: string, url: string) => void;
}

export const AuthContext = createContext<ContextType | null>(null);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<AuthUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth(app);

  useEffect(() => {
    const fetchAppUser = async (id: string) => {
      if (!id) {
        return null;
      }

      let appUser: AppUserDoc | null = null;

      appUser = await loadAppUser(id);
      if (!appUser) {
        console.error("No app user found for id: " + id);
      }

      return appUser;
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      let cUser: AuthUser = {};
      if (user) {
        const appUser: AppUserDoc | null = await fetchAppUser(user.uid);
        cUser = { id: appUser?.id, authUser: user, appUser: appUser?.user };
      }

      setCurrentUser(cUser);

      setIsLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  const isSignInWithEmail = (url: string): boolean => {
    console.log("check url for signin signature: " + url);
    const res = isSignInWithEmailLink(auth, url);
    console.log("res:", res);
    return res;
  };

  const sendSignInLinkToEmail = async (
    email: string,
    language: Language,
    checkIfRegistered: boolean
  ) => {
    console.log("check if registered (" + email + "): " + checkIfRegistered);

    // Check if the usert is registered first
    if (checkIfRegistered) {
      email = email.trim();
      console.log("check if registered: " + email);
      const isRegistered = await isUserRegistered(email);
      if (!isRegistered) {
        console.log("user already exists");
        return false;
      }
    }

    console.log("Email is registered.");

    language = language ? language : "de";
    const locale = language === "de" ? "de-DE" : "en-US";
    const date = new Date();
    const loginTime = `${date.toLocaleDateString(locale, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })} - ${date.toLocaleTimeString(locale, { hour: "2-digit", minute: "2-digit" })}`;

    const sendSignInLink = httpsCallable(functions, "api/v1/sendLoginLink");
    console.log("calling sendSignInLink");
    const response = await sendSignInLink({
      url: env.dev.signin_domain,
      email,
      language,
      loginTime,
    });

    if ((response.data as ResponseData).status === "OK") {
      localStorage.setItem("signInEmail", email);

      console.log("Email sent successfully.");
      return true;
    } else {
      console.log("Error sending email link.");
      return false;
    }
  };

  const signInWithEmail = async (email: string, url: string) => {
    signInWithEmailLink(auth, email, url)
      .then((user) => {
        localStorage.removeItem("signInEmail");
      })
      .catch((err) => {
        console.log("could not sign in with email link: ", err);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        isLoading,
        setIsLoading,
        sendSignInLinkToEmail,
        isSignInWithEmail,
        signInWithEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
