import { CssBaseline, ThemeProvider } from "@mui/material";
import "./App.css";
import { AuthProvider } from "./provider/auth-provider";
import theme from "./theme";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomePage from "./pages/pageHome";
import LoginPage from "./pages/pageLogin";
import { ValueProvider } from "./provider/value-provider";
import { LanguageProvider } from "./provider/language-provider";
import Header from "./components/header";
import StartPage from "./pages/pageStart";
import SelectPage from "./pages/pageSelect";
import NoValues from "./pages/pageNoValues";
import AdjustPage from "./pages/pageAdjust";
import FocusPage from "./pages/pageFocus";
import ReducePage from "./pages/pageReduce";
import ResultPage from "./pages/pageResults";
import UsersPage from "./pages/pageUsers";
import { ProtectedRoute } from "./components/protected-route";
import ValuesPage from "./pages/pageValues";
import SnackProvider from "./provider/snack-provider";
import ProfilePage from "./pages/pageProfile";
import RegisterPage from "./pages/pageRegister";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <LanguageProvider>
        <ValueProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <SnackProvider>
                <Header />
                <Routes>
                  <Route path="/no-values" element={<NoValues />} />
                  <Route path="/login" element={<LoginPage mode="login" />} />
                  <Route path="/register" element={<RegisterPage />} />
                  <Route
                    path="/start"
                    element={
                      <ProtectedRoute>
                        <StartPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/select"
                    element={
                      <ProtectedRoute>
                        <SelectPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/adjust"
                    element={
                      <ProtectedRoute>
                        <AdjustPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/focus"
                    element={
                      <ProtectedRoute>
                        <FocusPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/reduce"
                    element={
                      <ProtectedRoute>
                        <ReducePage />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/results/:id" element={<ResultPage />} />
                  <Route
                    path="/users"
                    element={
                      <ProtectedRoute onlyAdmin={true}>
                        <UsersPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <ProtectedRoute>
                        <ProfilePage />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/values" element={<ValuesPage />} />
                  <Route path="/" element={<HomePage />} />
                </Routes>
              </SnackProvider>
            </Router>
          </ThemeProvider>
        </ValueProvider>
      </LanguageProvider>
    </AuthProvider>
  );
};

export default App;
