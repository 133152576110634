import { useContext, useEffect, useState } from "react";
import PageWrapper, { PageTitle } from "../components/application-wrapper";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "./pageLoading";
import { LanguageContext } from "../provider/language-provider";
import { Box, Typography } from "@mui/material";
import { TypeStart } from "../types/contentful";
import { StyledButton } from "../components/customs";
import { getDescription } from "../components/richt-text-contentful";
import { AuthContext } from "../provider/auth-provider";
import env from "../env-config";

const StartPage = () => {
  const [testsAvailable, setTestsAvailable] = useState(false);

  const { currentUser, isLoading } = useContext(AuthContext)!;

  const { language } = useContext(LanguageContext)!;
  const fields = (useContentful("start") as TypeStart<"WITH_ALL_LOCALES">).fields;

  useEffect(() => {
    console.log("home page loaded...");
    console.log("user: ", currentUser);
    if (currentUser?.appUser) {
      setTestsAvailable(currentUser.appUser.availableTests > 0);
    } else {
      setTestsAvailable(false);
    }
  }, [currentUser]);

  if (!fields || isLoading) {
    return <LoadingWrapper title="Loading Application..." />;
  }

  return (
    <PageWrapper>
      <PageTitle>{fields.title && fields.title[language]}</PageTitle>
      {testsAvailable && (
        <Typography component="div">
          {getDescription(fields?.description ? fields.description[language] : null)}
        </Typography>
      )}

      {!testsAvailable && (
        <Typography component="div">
          {getDescription(
            fields?.descriptionTestsUnavailable
              ? fields.descriptionTestsUnavailable[language]
              : null
          )}
        </Typography>
      )}

      <Box display="flex" justifyContent="space-between">
        {testsAvailable && (
          <StyledButton variant="contained" href="/select" color="primary">
            {fields.button && fields.button[language]}
          </StyledButton>
        )}
        {!testsAvailable && (
          <StyledButton
            variant="contained"
            href={env.order_page.link}
            target={env.order_page.link[0] === "/" ? "" : "_blank"}
            color="primary"
          >
            {fields.buttonTestsUnavailable && fields.buttonTestsUnavailable[language]}
          </StyledButton>
        )}
      </Box>
    </PageWrapper>
  );
};

export default StartPage;
