import { Value, Values } from "../types/values";

export const shuffleArray = (array: any[]) => {
  console.log("shuflearray");

  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const cloneValues = (values: Values) => {
  if (!values) {
    return [];
  }
  return values.map((value) => value.clone());
};

export const valueFromJSON = (value: any) => {
  const newValue = new Value(value.id, value.translations);
  delete value.id;
  delete value.translations;
  Object.assign(newValue, value);

  return newValue;
};

export const valuesFromJSON = (values: any[]) => {
  const newValues: Values = [];
  values.forEach((value) => {
    newValues.push(valueFromJSON(value));
  });
  return newValues;
};

export const isEMailValid = (email: string | undefined): boolean => {
  return email ? /\S+@\S+\.\S+/.test(email) : false;
};

export function formatDateTime(unix: number | undefined): string {
  if (!unix) {
    unix = Date.now();
  }

  return `${new Date(unix * 1000).toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })} - ${new Date(unix * 1000).toLocaleTimeString()}`;
}

export const formatString = (template: string, values: string[]) => {
  return template.replace(/{(\d+)}/g, (match, index) => values[index] || "");
};
