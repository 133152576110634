import { User } from "firebase/auth";
import { TrackedRecord } from "./commons";
import { Results } from "./values";

export const EMPTY_APP_USER = {
  id: "",
  name: "",
  surname: "",
  email: "",
  availableTests: 0,
  isAdmin: false,
};

export interface AppUser extends TrackedRecord {
  id: string;
  name: string;
  surname: string;
  email: string;
  isAdmin: boolean;
  resetPasswordUponLogin?: boolean;
  resetExpiryDate?: string;
  disabled?: boolean;
  results?: Results;
  birthYear?: number;
  gender?: string;
  company?: string;
  department?: string;
  note1?: string;
  note2?: string;
  availableTests: number;
  language?: string;
}

export interface AuthUser {
  authUser?: User | null;
  appUser?: AppUser | null;
  id?: string;
}

export type AppUsersDict = Record<string, AppUser>;
export type AppUserDoc = { id: string; user: AppUser };
