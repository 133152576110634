import { Language } from "./languages";
import { TrackedRecord } from "./commons";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";

export interface Translation {
  info?: string;
  value: string;
  warning?: string;
}

export class Value {
  #id: string; // private field
  #selected?: boolean;
  #adjusted = false;
  #focused = 0;
  #enabled = true;
  #category: string = ""; // New private field for category
  translations: Record<string, Translation> = {};

  constructor(id = "new_id", translations = {}, category = "") {
    this.#id = id;
    this.translations = { ...translations };
    this.#category = category;
  }

  static fromId(id: string) {
    return new Value(id);
  }

  toFirestore() {
    return {
      enabled: this.#enabled,
      translations: this.translations,
      category: this.#category, // Include category in Firestore data
    };
  }

  static fromFireabase(doc: QueryDocumentSnapshot<DocumentData, DocumentData>) {
    const value = new Value(doc.id);
    const data = doc.data();
    value.translations = data.translations;
    value.enabled = data.enabled;
    value.category = data.category || ""; // Set category from Firestore data
    return value;
  }

  toResult() {
    return {
      id: this.#id,
      translations: this.translations,
      category: this.#category,
      selected: this.#selected,
      adjusted: this.#adjusted,
      focused: this.#focused,
    };
  }

  clone() {
    const clonedValue = new Value(this.#id, this.translations, this.#category);
    clonedValue.adjusted = this.#adjusted;
    clonedValue.selected = this.#selected;
    clonedValue.focused = this.#focused;
    return clonedValue;
  }

  get id() {
    return this.#id;
  }

  set id(id) {
    this.#id = id;
  }

  get enabled() {
    return this.#enabled;
  }

  set enabled(value) {
    this.#enabled = value;
  }

  // Getter for selected
  get selected() {
    return this.#selected;
  }

  // Setter for selected
  set selected(value) {
    this.#selected = value;
  }

  // Getter for adjusted
  get adjusted() {
    return this.#adjusted;
  }

  // Setter for adjusted
  set adjusted(value) {
    this.#adjusted = value;
  }

  // Getter for focused
  get focused() {
    return this.#focused;
  }

  // Setter for focused
  set focused(value) {
    this.#focused = value;
  }

  getTranslations() {
    return this.translations;
  }

  getTranslation(language: Language) {
    return this.translations[language as string];
  }

  addTranslation(language: Language, translation: Translation) {
    this.translations[language as string] = translation;
  }

  changeTranslation(language: Language, field: string, newValue: string) {
    if (!this.translations[language]) {
      this.translations[language as string] = { value: "" };
    }
    if (this.translations[language]) {
      this.translations[language as string][field as keyof Translation] = newValue;
    }
  }

  // Getter for category
  get category() {
    return this.#category;
  }

  // Setter for category
  set category(value: string) {
    this.#category = value;
  }
}

export type Values = Value[];
export type ValuesMap = Map<string, Value>;

export interface Result extends TrackedRecord {
  uid: string;
  values: Values;
}

export type ResultDoc = { id: string; result: Result };
export type Results = Map<string, Result>;
