import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@mui/material";
import header_icon from "../ressources/valueverse.gif";
import { useContext, useState } from "react";
import { AuthContext } from "../provider/auth-provider";
import { Language, languageMappping, SUPPORTED_LANGUAGES } from "../types/languages";
import { LanguageContext } from "../provider/language-provider";
import { StyledButton } from "./customs";
import { bgMainColor } from "../theme";
import { signOut } from "firebase/auth";
import { auth } from "../types/firebase";
import { Logout } from "@mui/icons-material";

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorLanguage, setAnchorLanguage] = useState<HTMLElement | null>(null);
  const { language, setLanguage } = useContext(LanguageContext)!;

  const { currentUser } = useContext(AuthContext)!;
  const user = currentUser?.appUser;

  const handleLanguageClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLanguage(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorLanguage(null);
  };

  const handleLanguageChange = (event: React.MouseEvent<HTMLLIElement>, newLanguage: Language) => {
    if (newLanguage) {
      setLanguage(newLanguage);
      setAnchorLanguage(null);
    }
  };

  const openProfileMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    window.location.href = "/profile";
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error);
    }
    handleClose();
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ minHeight: "44px" }}>
        <Link href="/" sx={{ flexGrow: 0 }}>
          <img
            src={header_icon} // Replace with your logo path
            alt="Logo"
            style={{ width: "100px", maxWidth: "100px" }}
          />
        </Link>
        <Box sx={{ flexGrow: 1 }} /> {/* Empty container to push the button to the end */}
        <StyledButton
          variant="outlined"
          startIcon={
            <img
              src={`https://flagcdn.com/w40/${languageMappping[language]}.png`}
              alt={language}
              style={{
                width: "24px",
                height: "24px",
                objectFit: "contain",
              }}
            />
          }
          onClick={handleLanguageClick}
          className="language-switcher"
        >
          {language}
        </StyledButton>
        <Menu
          id="language-menu"
          anchorEl={anchorLanguage}
          keepMounted
          open={!!anchorLanguage}
          onClose={handleLanguageClose}
        >
          {SUPPORTED_LANGUAGES.map((lang) => (
            <MenuItem key={lang} onClick={(event) => handleLanguageChange(event, lang)}>
              <img
                src={`https://flagcdn.com/w40/${languageMappping[lang]}.png`}
                alt={lang}
                style={{ width: "24px", height: "24px", objectFit: "contain", marginRight: "8px" }}
              />
              {lang.toLocaleUpperCase()}
            </MenuItem>
          ))}
        </Menu>
        {currentUser && currentUser.authUser ? (
          <Tooltip title="Profile">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={openProfileMenu ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openProfileMenu ? "true" : undefined}
            >
              <Avatar
                sx={{
                  width: 36,
                  height: 36,
                  backgroundColor: bgMainColor,
                  color: "black",
                  border: "1px solid black",
                }}
              >
                {user && user.name ? user.name[0] + user.surname[0] : ""}
              </Avatar>
            </IconButton>
          </Tooltip>
        ) : (
          <Button color="inherit" href="/login">
            Login
          </Button>
        )}
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openProfileMenu}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
              minWidth: "250px",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={handleProfileOpen}>
            <Avatar /> Profile
          </MenuItem> 

        <Divider key="divider2" variant="inset" light />*/}

        {currentUser && currentUser.appUser && currentUser.appUser.isAdmin && (
          <div>
            <MenuItem component={"a"} href="/values" onClick={handleClose}>
              <Box width={32} height={32} marginLeft="-4px" marginRight="8px" />
              Values
            </MenuItem>
            <Divider key="divider2" variant="inset" light />
            <MenuItem component={"a"} href="/users" onClick={handleClose}>
              <Box width={32} height={32} marginLeft="-4px" marginRight="8px" />
              User Management
            </MenuItem>
            <Divider key="divider3" variant="inset" light />
          </div>
        )}

        <MenuItem onClick={handleProfileClick}>
          {" "}
          <Box width={32} height={32} marginLeft="-4px" marginRight="8px" />
          Profile
        </MenuItem>

        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Header;
