import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { Result, ResultDoc, Results, Value, ValuesMap } from "../types/values";
import db from "../types/firebase";
import { valuesFromJSON } from "../utils/common";

export const storeValue = async (value: Value) => {
  try {
    let userSnapshot = null;
    if (!value.id || value.id === "new_id") {
      const id = (await addDoc(collection(db, "values"), value.toFirestore())).id;
      value.id = id;
      return value;
    } else {
      const docRef = doc(db, "values", value.id);
      userSnapshot = await getDoc(docRef);
      // Update the document
      userSnapshot && (await setDoc(docRef, value.toFirestore()));
      return value;
    }
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const deleteValue = async (valueId: string) => {
  try {
    await deleteDoc(doc(db, "values", valueId));
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
};

export const loadValues = async (onlyEnabled: boolean) => {
  const values: ValuesMap = new Map();
  try {
    let valuesQuery;
    if (onlyEnabled) {
      valuesQuery = query(collection(db, "values"), where("enabled", "==", true));
    } else {
      valuesQuery = collection(db, "values");
    }
    const querySnapshot = await getDocs(valuesQuery);

    querySnapshot.forEach((doc) => {
      const value = Value.fromFireabase(doc);
      values.set(value.id, value);
    });
    return values;
  } catch (e) {
    console.log("Error getting  document:", e);
    return values;
  }
};

export const loadResultById = async (resultId: string) => {
  if (!resultId) {
    return null;
  }

  try {
    const docRef = doc(db, "results", resultId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const result = docSnap.data() as Result;
      result.values = valuesFromJSON(result.values);
      return result;
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (e) {
    console.log("Error getting document:", e);
    return null;
  }
};

export const loadResults = async (userId: string) => {
  try {
    const resultsQuery = query(collection(db, "results"), where("uid", "==", userId));

    const results: Results = new Map();
    const querySnapshot = await getDocs(resultsQuery);
    querySnapshot.forEach((doc) => {
      const result = doc.data() as Result;
      result.values = valuesFromJSON(result.values);
      results.set(doc.id, result);
    });
    return results;
  } catch (e) {
    console.log("Error getting  document:", e);
    return new Map();
  }
};

export const storeResult = async (resultDoc: ResultDoc) => {
  try {
    const result = resultDoc.result;
    if (!result.createdAt) {
      result.createdAt = Timestamp.now();
    }
    let userSnapshot = null;
    const newResult = { ...result, values: result.values.map((v) => v.toResult()) };
    if (!resultDoc.id) {
      console.log("storing new results: ", result);
      const id = (await addDoc(collection(db, "results"), newResult)).id;
      resultDoc.id = id;
      return resultDoc;
    } else {
      const docRef = doc(db, "results", resultDoc.id);
      userSnapshot = await getDoc(docRef);
      // Update the document
      userSnapshot && (await setDoc(docRef, newResult));
      return resultDoc;
    }
  } catch (e) {
    console.error("Error adding document: ", e);
    return null;
  }
};
