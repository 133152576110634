import React, { useContext, useEffect, useState } from "react";
import { Results } from "../types/values";
import { AuthContext } from "../provider/auth-provider";
import { ValueContext } from "../provider/value-provider";
import useContentful from "../hooks/useContentful";
import { TypeProfile } from "../types/contentful";
import LoadingWrapper from "./pageLoading";
import PageWrapper, { PageTitle } from "../components/application-wrapper";
import Spacer from "../components/spacer";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { formatDateTime, formatString } from "../utils/common";
import { LanguageContext } from "../provider/language-provider";
import { StyledButton } from "../components/customs";
import env from "../env-config";
import { getDescription } from "../components/richt-text-contentful";
import StarEvaluation from "../components/star-evaluation";
import { useNavigate } from "react-router-dom";

const StyledAccordionDetails = styled(AccordionDetails)`
  display: block;
`;

const ProfilePage = () => {
  const [resultsData, setResultsData] = useState<Results>(new Map());

  const { currentUser } = useContext(AuthContext)!;

  const { getResultsByUserId, initialized, initValues } = useContext(ValueContext)!;

  const { language } = useContext(LanguageContext)!;
  const fields = (useContentful("profile") as TypeProfile<"WITH_ALL_LOCALES">).fields;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!(currentUser?.id && currentUser?.appUser)) {
        console.log("user not logged in...");
        return;
      }
      const newData = await getResultsByUserId(currentUser.id);
      setResultsData(newData);
    };

    fetchData();
  }, [currentUser]);

  if (!currentUser?.appUser || !resultsData || !fields) {
    return <LoadingWrapper />;
  }

  return (
    <React.Fragment>
      <PageWrapper minheight="150px">
        <PageTitle>Hallo, {currentUser?.appUser.name}</PageTitle>
        <Spacer i={2} />
        {!!currentUser?.appUser.availableTests && currentUser?.appUser.availableTests > 0 && (
          <Typography variant="body1">
            {formatString(
              (fields.descriptionAvailableTests && fields.descriptionAvailableTests[language]) ||
                "",
              [
                currentUser.appUser.availableTests.toString(),
                currentUser?.appUser.availableTests === 1 ? "" : "s",
              ]
            )}
          </Typography>
        )}
        {!currentUser?.appUser.availableTests && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" component={"div"}>
              {fields.descriptionNoTestsAvailable && fields.descriptionNoTestsAvailable[language]}
            </Typography>{" "}
            <StyledButton
              variant="contained"
              href={env.order_page.link}
              target={env.order_page.link[0] === "/" ? "" : "_blank"}
              color="primary"
              sx={{ marginLeft: "2em" }}
            >
              {fields.buttonTestsUnavailable && fields.buttonTestsUnavailable[language]}
            </StyledButton>
          </Box>
        )}
        <Spacer />
      </PageWrapper>
      <PageWrapper>
        <PageTitle>{fields.title && fields.title[language]}</PageTitle>
        <Typography component="div">
          {getDescription(fields.description ? fields.description[language] : null)}
        </Typography>
        <Spacer />
        {Array.from(resultsData.entries()).map(([id, data]) => (
          <Accordion key={id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography component="div">{formatDateTime(data?.createdAt?.seconds)}</Typography>
            </AccordionSummary>
            <StyledAccordionDetails>
              <StarEvaluation focusedValues={data.values} />
              <Button onClick={() => navigate(`/results/${id}`)} variant="contained">
                {fields.buttonResultPage && fields.buttonResultPage[language]}
              </Button>
            </StyledAccordionDetails>
          </Accordion>
        ))}
      </PageWrapper>
    </React.Fragment>
  );
};

export default ProfilePage;
