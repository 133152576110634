import React, { useContext, useEffect, useState, useMemo } from "react";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete as DeleteIcon, ViewAgenda } from "@mui/icons-material";
import { EnhancedTableHead } from "../components/enhanced-table";
import { getComparator } from "../types/tables";
import { isEqual } from "lodash";
import { Value, ValuesMap } from "../types/values";
import { HeadCells, Order } from "../utils/tables";
import { AuthContext } from "../provider/auth-provider";
import { DEFAULT_LANGUAGE, Language, SUPPORTED_LANGUAGES } from "../types/languages";
import { deleteValue, loadValues, storeValue } from "../data-functions/values-api";

const headCells: HeadCells = [
  {
    id: "value",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sortable: true,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    sortable: false,
  },
];

const ValuesPage = () => {
  const [values, setValues] = useState<ValuesMap>(new Map());
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState("value");
  const [selectedValue, setSelectedValue] = useState<Value | null>(null);

  const { currentUser } = useContext(AuthContext)!;

  const isAdmin = currentUser?.appUser?.isAdmin || false;

  useEffect(() => {
    async function fetchValues() {
      const newValues = await loadValues(false);
      const valuesMap = new Map();
      newValues.forEach((value) => {
        valuesMap.set(value.id, value);
      });
      setValues(valuesMap);
    }

    fetchValues();
  }, []);

  const handleAddValue = async () => {
    if (selectedValue) {
      const val = selectedValue.id !== "new_id" ? values.get(selectedValue.id) : null;
      console.log("val: ", val);
      console.log("selectedValue: ", selectedValue);
      if (!isEqual(val, selectedValue)) {
        const conf = window.confirm(
          "The selected value has been changed! Press OK if you want to discard the changes."
        );
        if (!conf) {
          return;
        }
      }
    }

    const newValue = new Value();
    newValue.translations[DEFAULT_LANGUAGE] = {
      value: "",
      info: "",
      warning: "",
    };
    setSelectedValue(newValue);
  };

  const handleDelete = async (valueId: string) => {
    if (window.confirm("Are you sure you want to delete this value?")) {
      await deleteValue(valueId);
      const newValues = new Map(values);
      newValues.delete(valueId);
      setValues(newValues);
      setSelectedValue(null);
      //   if (selectedValue && selectedValue.getId() === valueId) {
      //     setSelectedValue(null);
      //   }
    }
  };

  const handleSelectValue = async (valueId: string) => {
    if (valueId && values.get(valueId)) {
      let value = values.get(valueId);
      console.log("same object: ", value === values.get(valueId));
      if (value) {
        value = value.clone();
        if (selectedValue && selectedValue.id !== valueId) {
          const val = selectedValue.id !== "new_id" ? values.get(selectedValue.id) : null;

          if (!isEqual(val, selectedValue)) {
            const conf = window.confirm(
              "The selected value has been changed! Press OK if you want to discard the changes."
            );
            if (!conf) {
              return;
            }
          }
        }
        console.log("selected value: ", value);
        setSelectedValue(value);
      }
    }
  };

  const handleChangeValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name.split("_");
    console.log("target name: ", name);
    const field = name[0];
    const lang = name[1] as Language;
    const value = e.target.value;

    if (!(field && lang)) {
      return;
    }

    if (selectedValue) {
      const newSelectedValue = selectedValue.clone();
      console.log("same object: ", newSelectedValue === selectedValue);
      newSelectedValue.changeTranslation(lang, field, value);
      console.log("setting new value: ", newSelectedValue);
      setSelectedValue(newSelectedValue);
    }
  };

  const handleSave = async () => {
    if (selectedValue) {
      const newValue = await storeValue(selectedValue);
      if (newValue) {
        const newValues = new Map(values);
        newValues.set(newValue.id, newValue);
        setValues(newValues);
        setSelectedValue(null);
      }
    }
  };

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a: Value, b: Value, orderBy: string) {
    const value1 = a.translations[DEFAULT_LANGUAGE];
    const value2 = b.translations[DEFAULT_LANGUAGE];

    // console.log("a1: ");
    // console.log(value1);

    // console.log("a2: ");
    // console.log(value2);

    // console.log("orderBy: " + orderBy);

    if (value1 && value2) {
      let res = 0;
      switch (orderBy) {
        case "value":
          if (value2.value < value1.value) {
            res = -1;
          }
          if (value2.value > value1.value) {
            res = 1;
          }

          return res;

        default:
          break;
      }
    } else {
      if (value1 && !value2) {
        return -1;
      } else if (!value1 && value2) {
        return 1;
      }
    }
    return 0;
  }

  const filteredValues = useMemo(
    () =>
      [...values.values()].filter((value) => {
        return (
          searchValue.length < 3 ||
          (value.translations[DEFAULT_LANGUAGE] &&
            value.translations[DEFAULT_LANGUAGE].value
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()))
        );
      }),
    [searchValue, values]
  );

  const visibleRows = useMemo(
    () =>
      filteredValues
        .slice()
        .sort(getComparator(order, orderBy as keyof Value, descendingComparator)),
    [order, orderBy, filteredValues]
  );

  // const handleTestClick = async () => {
  //   const newValues: ValuesMap = await loadValues(false);
  //   console.log("keys:", newValues.keys());
  //   const data = Array.from(newValues.values()).map((value: Value) => {
  //     return value.toFirestore();
  //   });

  //   const content = JSON.stringify(data);
  //   const blob = new Blob([content], { type: "application/json" });
  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "values.json";
  //   a.click();

  //   console.log("data: ", data);
  // };

  // const handleImportValues = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (!file) return;

  //   const reader = new FileReader();
  //   reader.onload = async (e) => {
  //     try {
  //       const content = e.target?.result as string;
  //       const importedValues: Value[] = JSON.parse(content);

  //       if (importedValues.length > 0) {
  //         const valuePromises = importedValues.map((value) => {
  //           const v = new Value(value.id);
  //           v.translations = value.translations;
  //           console.log("v: ", v);
  //           console.log("v to firestore: ", v.toFirestore());
  //           return storeValue(v);
  //         });

  //         const storedValues = await Promise.all(valuePromises);
  //         const newValues = new Map(values);
  //         storedValues.forEach((value) => {
  //           if (value) {
  //             newValues.set(value.id, value);
  //           }
  //         });
  //         setValues(newValues);
  //         setSelectedValue(null);
  //       }
  //     } catch (error) {
  //       console.error("Error importing values:", error);
  //       alert("Failed to import values. Please check the file format.");
  //     }
  //   };

  //   reader.readAsText(file);
  // };

  return (
    <Box
      sx={{
        margin: "1.5rem",
        marginTop: "1rem",
      }}
    >
      <Typography variant="h5" fontWeight={"bold"}>
        Values
      </Typography>
      <Box
        sx={{
          marginTop: "1rem",
        }}
      >
        {isAdmin && (
          <Box>
            <Box display={"flex"} sx={{ gap: 2 }}>
              {/* <Button variant="outlined" color="primary" onClick={handleAddValue}>
                Add New Value
              </Button>
              <Button variant="outlined" color="primary" onClick={handleTestClick}>
                Test
              </Button> */}
              {/* <Button
                variant="outlined"
                color="primary"
                onClick={() => document.getElementById("import-file-input")?.click()}
              >
                Import Values
              </Button>
              <input
                type="file"
                id="import-file-input"
                accept="application/json"
                style={{ display: "none" }}
                onChange={handleImportValues}
              /> */}
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search..."
                value={searchValue}
                onChange={handleSearchValueChange}
                sx={{ minWidth: "250px" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1rem",
                minHeight: "400px",
                height: "calc(100vh - 310px)",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  flexBasis: "0",
                  maxWidth: `calc(100% - 450px)`,
                  marginRight: "8px",
                }}
              >
                <TableContainer component={Paper} sx={{ height: "100%" }}>
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <EnhancedTableHead
                      numSelected={0}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={() => {}}
                      onRequestSort={handleRequestSort}
                      rowCount={visibleRows.length}
                      headCells={headCells}
                    />
                    <TableBody>
                      {visibleRows.map((value) => {
                        return (
                          <TableRow
                            key={`row-${value.id}`}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            onClick={() => handleSelectValue(value.id)}
                            className={selectedValue?.id === value.id ? "selected" : ""}
                          >
                            <TableCell scope="row" size="small">
                              {value.translations[DEFAULT_LANGUAGE]?.value || "No name"}
                            </TableCell>

                            <TableCell align="right" style={{ width: 40 }} size="small">
                              <Tooltip title="Delete">
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleDelete(value.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* ------------- Value Details ------------- */}
              <Box sx={{ width: "48%" }}>
                {selectedValue && (
                  <Card>
                    <CardHeader
                      title={selectedValue.translations[DEFAULT_LANGUAGE]?.value || "New Value"}
                    />
                    <CardContent>
                      {SUPPORTED_LANGUAGES.map((lang: Language) => {
                        return (
                          <React.Fragment key={lang + "-section"}>
                            <Divider
                              textAlign="left"
                              id={`${lang}-divider`}
                              key={`${lang}-divider`}
                            >
                              {lang.toUpperCase()}
                            </Divider>
                            <TextField
                              fullWidth
                              label={"Value"}
                              name={"value_" + lang}
                              variant="outlined"
                              size="small"
                              value={selectedValue.translations[lang]?.value || ""}
                              onChange={handleChangeValue}
                              sx={{ mt: 2, mb: 1 }}
                              key={`${lang}-value`}
                            />
                            <TextField
                              fullWidth
                              label={"Info"}
                              name={"info_" + lang}
                              variant="outlined"
                              size="small"
                              value={selectedValue.translations[lang]?.info || ""}
                              onChange={handleChangeValue}
                              sx={{ mt: 1, mb: 1 }}
                              key={`${lang}-info`}
                            />
                            <TextField
                              fullWidth
                              label={"Warnung"}
                              name={"warning_" + lang}
                              variant="outlined"
                              size="small"
                              multiline
                              rows={4}
                              value={selectedValue.translations[lang]?.warning || ""}
                              onChange={handleChangeValue}
                              sx={{ mt: 1, mb: 3 }}
                              key={`${lang}-warning`}
                            />
                          </React.Fragment>
                        );
                      })}
                    </CardContent>
                    <CardActions sx={{ padding: 2 }}>
                      <Box flexGrow={1}></Box>
                      <Button variant="contained" sx={{ mr: 2 }} onClick={handleSave}>
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setSelectedValue(null);
                        }}
                        sx={{ mr: 2 }}
                      >
                        Cancel
                      </Button>
                    </CardActions>
                  </Card>
                )}
              </Box>
            </Box>
          </Box>
        )}
        {!isAdmin && (
          <>
            <p>This is a protected page. Only users with the admin role can access it.</p>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ValuesPage;
