const env = {
  selected: {
    max: 20,
  },
  adjusted: {
    max: 10,
  },
  order_page: {
    link: "https://valueverse.de/products/online-werte-test",
  },
  dev: {
    videos: process.env.NODE_ENV === "production" ? true : false,
    dumped: process.env.NODE_ENV === "production" ? false : false,
    inputs:
      process.env.NODE_ENV === "production"
        ? { email: "", password: "", marketingOptin: false }
        : {
            email: "christo.ivanov@realizon.eu",
            password: "F79ffshsT7e5R7n",
            marketingOptin: false,
          },
    signin_domain:
      process.env.NODE_ENV === "production"
        ? "https://valueverse-36892.web.app/login/"
        : "http://localhost:3006/login/",
  },
  menu: [
    {
      href: "/imprint",
      title: "Impressum",
      target: "_blank",
    },
    {
      href: "https://www.iubenda.com/privacy-policy/67561422/legal",
      title: "Datenschutzerklärung",
      target: "_blank",
    },
    {
      href: "https://www.iubenda.com/privacy-policy/67561422/cookie-policy",
      title: "Cookie-Richtlinie",
      target: "_blank",
    },
  ],
};

export default env;
