import React, { FormEvent, useContext, useEffect, useState } from "react";
import { Box, Container, Divider, Grid, Link, TextField, Typography } from "@mui/material";
import { AuthContext } from "../provider/auth-provider";
import { useLocation, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../types/firebase";
import { StyledButtonInverted } from "../components/customs";
import PageWrapper, { PageTitle } from "../components/application-wrapper";
import { isEMailValid } from "../utils/common";
import { existsAppUserEMail, storeAppUser } from "../data-functions/users-api";
import { AppUser, EMPTY_APP_USER } from "../types/users";
import { LanguageContext } from "../provider/language-provider";
import LoadingWrapper from "./pageLoading";
import useContentful from "../hooks/useContentful";
import { TypeSignin } from "../types/contentful";
import { getDescription } from "../components/richt-text-contentful";
import Spacer from "../components/spacer";

interface LoginPageProps {
  mode?: "login" | "signup" | "finishsignup";
}

const EMPTY_ERRORS = {
  name: "",
  surname: "",
  email: "",
  password: "",
  confirmPassword: "",
  error: "",
};

const LoginPage = ({ mode = "login" }: LoginPageProps) => {
  const [appUser, setAppUser] = useState<AppUser>({ ...EMPTY_APP_USER });
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const [signing, setSigning] = useState(false);
  const [error, setError] = useState({ ...EMPTY_ERRORS });

  const [activeStep, setActiveStep] = useState("loading");
  const [sendingLink, setSendingLink] = useState(false);

  const {
    currentUser,
    setCurrentUser,
    isLoading,
    sendSignInLinkToEmail,
    isSignInWithEmail,
    signInWithEmail,
  } = useContext(AuthContext)!;
  const { language } = useContext(LanguageContext)!;

  const fields = (useContentful("signin") as TypeSignin<"WITH_ALL_LOCALES">).fields;

  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;

  const doSignIn = async () => {
    // user is not signed in
    // check if the user clicked the email link
    if (isSignInWithEmail(window.location.href)) {
      console.log("signature is in the email link");
      // now in case user clicks the email link on a different device, we will ask for email confirmation
      let email = localStorage.getItem("signInEmail");
      if (!email) {
        email = window.prompt("Please provide your email");
        return;
      }
      signInWithEmail(email, window.location.href);
    } else {
      console.log("signature is not in the email link");
      setActiveStep("login");
      setSendingLink(false);
    }
  };

  useEffect(() => {
    if (isLoading) return;

    // If user is already logged in, redirect to home
    if (currentUser && currentUser.authUser) {
      console.log("redirecting logged in user");

      setActiveStep("loading");

      if (mode === "login") {
        if (currentUser.appUser) {
          if (currentUser.appUser.isAdmin) {
            navigate("/users", location);
          } else navigate("/start", location);
        } else navigate("/", location);
      }
    } else {
      doSignIn();
    }
  }, [currentUser, isLoading, search]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUser: AppUser = {
      ...appUser,

      [event.target.name]: event.target.value,
    };

    setAppUser(newUser);
  };

  const validate = async () => {
    let valid = true;
    let newErrors = { ...EMPTY_ERRORS };

    if (!appUser.email || !isEMailValid(appUser.email)) {
      valid = false;
      newErrors.email = "Please enter a valid email address";
    } else {
      if (mode === "signup") {
        const userExists = await existsAppUserEMail(appUser.email);
        if (userExists) {
          valid = false;
          newErrors.email = "User already exists with this email address";
        }
      }
    }

    // if (!password) {
    //   valid = false;
    //   newErrors.password = "Please enter a password";
    // }

    // if (mode === "signup") {
    //   if (!appUser.name) {
    //     valid = false;
    //     newErrors.name = "Please, enter a valid name";
    //   }

    //   if (!appUser.surname) {
    //     valid = false;
    //     newErrors.surname = "Please, enter a valid name";
    //   }

    //   if (!passwordConfirmation) {
    //     valid = false;
    //     newErrors.confirmPassword = "Please confirm your password";
    //   } else if (password !== passwordConfirmation) {
    //     valid = false;
    //     newErrors.confirmPassword = "Passwords do not match";
    //   } else {
    //     if (password.length < 6) {
    //       valid = false;
    //       newErrors.password = "Password must be at least 6 characters long";
    //     }
    //   }
    // }

    if (!valid) {
      setError({ ...newErrors });
    }

    return valid;
  };

  const handleLogin = async (event: FormEvent) => {
    console.log("logging in...");
    event.preventDefault();
    setSigning(true);
    const valid = await validate();
    if (!valid) {
      setSigning(false);
      return;
    }

    sendSignInLinkToEmail(appUser.email, language, true)
      .then((res) => {
        if (res) {
          console.log("user signed in");
          setActiveStep("email");
        } else {
          setError({ ...error, error: "Could not log in!" });
        }
      })
      .catch((err) => {
        setError({ ...error, error: "Could not log in!" });
      })
      .finally(() => {
        setSigning(false);
      });
  };

  const handleSignup = async (event: FormEvent) => {
    event.preventDefault();
    setSigning(true);
    const valid = await validate();
    if (!valid) {
      setSigning(false);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, appUser.email, password);
      // Signed in
      if (userCredential.user) {
        console.log("user signed up");
        appUser.id = userCredential.user.uid;
        const newUser = await storeAppUser(null, { id: "", user: appUser }, true);
        if (newUser?.user) {
          const authUser = { authUser: userCredential.user, appUser: newUser.user };
          setCurrentUser(authUser);
        }
        //TODO: send email verification link
      } else {
        console.log("user could not be signed up");
      }
    } catch (err: any) {
      setError({ ...error, error: "Could not sign up! Please try again later" });
      // Handle errors here
    } finally {
      setSigning(false);
    }
  };

  const getLoginPage = () => {
    console.log("activeStep: ", activeStep);
    if (activeStep === "login") {
      return (
        <React.Fragment>
          <Typography component={"div"}>{"Sign in to continue."}</Typography> <Spacer i={2} />
          <TextField
            type="email"
            placeholder="Email"
            fullWidth
            name="email"
            variant="outlined"
            value={appUser.email}
            onChange={handleInputChange}
            error={!!error.email}
            helperText={error.email}
            margin="dense"
            size="small"
            required
            autoFocus
          />
          <Spacer i={2} />
          <Box display="flex" justifyContent="space-between">
            <StyledButtonInverted
              variant="contained"
              color="primary"
              type="submit"
              className="button-block"
              disabled={signing}
              onClick={handleLogin}
            >
              {fields.button && fields.button[language]}
            </StyledButtonInverted>
          </Box>
          <Spacer i={2} />
          <Typography variant="body2">
            {fields.signupCta && fields.signupCta[language]}{" "}
            <Link href="/register" color="primary">
              {fields.signupButton && fields.signupButton[language]}
            </Link>
            !
          </Typography>
        </React.Fragment>
      );
    }

    if (activeStep === "email") {
      return (
        <React.Fragment>
          <Typography variant="h5">
            {fields.titleMailSent && fields.titleMailSent[language]}
          </Typography>
          <Typography component="div">
            {getDescription(fields.descriptionMailSent && fields.descriptionMailSent[language])}
          </Typography>
        </React.Fragment>
      );
    }
  };

  const getSignupPage = () => {
    return (
      <React.Fragment>
        <Typography component={"div"}>{"Sign up for your value exploration"}</Typography>
        <Grid container direction="column" spacing={1} paddingX={3} paddingBottom={3}>
          <Grid item>
            <TextField
              type="text"
              placeholder="Name"
              fullWidth
              name="name"
              variant="outlined"
              size="small"
              value={appUser.name}
              onChange={handleInputChange}
              error={!!error.name}
              helperText={error.name}
              margin="dense"
              required
              autoFocus
            />
          </Grid>
          <Grid item>
            <TextField
              type="text"
              placeholder="Family Name"
              fullWidth
              name="surname"
              variant="outlined"
              size="small"
              value={appUser.surname}
              onChange={handleInputChange}
              error={!!error.surname}
              helperText={error.surname}
              margin="dense"
              required
              autoFocus
            />
          </Grid>
          <Grid item>
            <TextField
              type="email"
              placeholder="Email"
              fullWidth
              name="email"
              variant="outlined"
              size="small"
              value={appUser.email}
              onChange={handleInputChange}
              error={!!error.email}
              helperText={error.email}
              margin="dense"
              required
              autoFocus
            />
          </Grid>
          <Grid item>
            <TextField
              type="password"
              placeholder="Password"
              fullWidth
              name="password"
              variant="outlined"
              margin="dense"
              size="small"
              value={password}
              error={!!error.password}
              helperText={error.password}
              onChange={(event) => setPassword(event.target.value)}
              required
            />
          </Grid>
          <Grid item>
            <TextField
              type="password"
              placeholder="Confirm Password"
              fullWidth
              name="passwordConfirmation"
              variant="outlined"
              margin="dense"
              size="small"
              value={passwordConfirmation}
              error={!!error.confirmPassword}
              helperText={error.confirmPassword}
              onChange={(event) => setPasswordConfirmation(event.target.value)}
              required
            />
          </Grid>
          <Grid item>
            <Grid container direction={"row"} justifyContent={"flex-end"}>
              <StyledButtonInverted
                variant="contained"
                color="primary"
                type="submit"
                className="button-block"
                fullWidth
                disabled={signing}
                onClick={handleSignup}
              >
                Sign up
              </StyledButtonInverted>
            </Grid>
            <Grid item>
              <Divider
                textAlign="center"
                sx={{ marginTop: "2rem", fontWeight: "400", fontSize: "1rem" }}
              >
                or sign up with
              </Divider>
            </Grid>
            <Grid item>
              <Typography align="center" variant="body2" sx={{ marginTop: "2rem" }}>
                Have an account already?{" "}
                <Link variant="body2" href="/login">
                  Login
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const getPage = () => {
    switch (mode) {
      case "login":
        return getLoginPage();
      case "signup":
        return getSignupPage();

      default:
        return getLoginPage();
    }
  };

  const getLoadingScreen = () => {
    console.log("activeStep: ", activeStep);

    return <LoadingWrapper title="Loading Application..." />;
  };

  if (!fields || isLoading) {
    console.log("Sign in loading...");
    console.log("fields: ", !!fields);
    console.log("isLoading: ", isLoading);
    return getLoadingScreen();
  }

  return (
    <Box maxWidth="500px" mx={"auto"}>
      {activeStep === "loading" && getLoadingScreen()}
      {activeStep !== "loading" && (
        <PageWrapper>
          <Box display="flex" justifyContent="space-between">
            <PageTitle>{fields.title && fields.title[language]}</PageTitle>
          </Box>
          <Spacer i={2} />
          {getPage()}
        </PageWrapper>
      )}
    </Box>
  );
};

export default LoginPage;
