import { useState, ReactNode, createContext } from "react";
import { useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";

import { Button, IconButton, Snackbar } from "@mui/material";

interface SnackContext {
  message: string;
  button: string;
  closeFn?: () => void;
}

interface SnackProviderProps {
  children: ReactNode;
}

interface SnackNotificationContext {
  triggerSnackbar: (updated: Partial<SnackContext>) => void;
}

const snackNotification = createContext<SnackNotificationContext | undefined>(undefined);

function SnackProvider({ children }: SnackProviderProps) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [context, setContext] = useState<SnackContext>({
    message: "Erfolg!",
    button: "Weiter",
  });

  function triggerSnackbar(updated: Partial<SnackContext>) {
    setOpen(true);
    setContext((prevContext) => ({ ...prevContext, ...updated }));
  }

  function handleClose(event?: React.SyntheticEvent | Event) {
    setOpen(false);
    if (context.closeFn) {
      context.closeFn();
    }
  }

  return (
    <snackNotification.Provider
      value={{
        triggerSnackbar,
      }}
    >
      <>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={context.message}
          action={
            <>
              {context.button && (
                <Button color="primary" size="small" onClick={handleClose}>
                  {context.button}
                </Button>
              )}
              <IconButton aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </>
          }
        />
        {children}
      </>
    </snackNotification.Provider>
  );
}
export default SnackProvider;
export { snackNotification };
