import React, { FormEvent, useContext, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { AuthContext } from "../provider/auth-provider";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledButtonInverted } from "../components/customs";
import PageWrapper, { PageTitle } from "../components/application-wrapper";
import { isEMailValid } from "../utils/common";
import { existsAppUserEMail, registerUser } from "../data-functions/users-api";
import { AppUser, EMPTY_APP_USER } from "../types/users";
import { LanguageContext } from "../provider/language-provider";
import LoadingWrapper from "./pageLoading";
import useContentful from "../hooks/useContentful";
import { TypeSignup } from "../types/contentful";
import { getDescription } from "../components/richt-text-contentful";
import Spacer from "../components/spacer";
import { sendVerificationEmail } from "../data-functions/auth-api";
import { set } from "lodash";

const EMPTY_ERRORS = {
  name: "",
  surname: "",
  email: "",
  error: "",
  terms: "",
};

const RegisterPage = () => {
  const [appUser, setAppUser] = useState<AppUser>({ ...EMPTY_APP_USER });
  const [error, setError] = useState({ ...EMPTY_ERRORS });

  const [termsAccepted, setTermsAccepted] = useState(false);

  const [activeStep, setActiveStep] = useState("signup");
  const [registering, setRegistering] = useState(false);

  const { currentUser, isLoading } = useContext(AuthContext)!;
  const { language } = useContext(LanguageContext)!;

  const fields = (useContentful("signup") as TypeSignup<"WITH_ALL_LOCALES">).fields;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTermsAccepted(false);
    setError({ ...EMPTY_ERRORS });
    setAppUser({ ...EMPTY_APP_USER });
  }, []);

  useEffect(() => {
    if (isLoading) return;

    // If user is already logged in, redirect to home
    if (currentUser && currentUser.authUser) {
      console.log("redirecting logged in user");

      if (currentUser.appUser) {
        if (currentUser.appUser.isAdmin) {
          navigate("/users", location);
        } else navigate("/start", location);
      } else navigate("/", location);
    } else {
      setActiveStep("signup");
      setRegistering(false);
    }
  }, [currentUser, isLoading]);

  useEffect(() => {
    setError({ ...EMPTY_ERRORS });
  }, [language]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUser: AppUser = {
      ...appUser,

      [event.target.name]: event.target.value,
    };

    setAppUser(newUser);
  };

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  const validate = async () => {
    let valid = true;
    let newErrors = { ...EMPTY_ERRORS };

    if (!appUser.email || !isEMailValid(appUser.email)) {
      valid = false;
      newErrors.email =
        (fields.errorMail && fields.errorMail[language]) ||
        "Bitte, geben Sie eine gültige E-Mail-Adresse ein";
    } else {
      const userExists = await existsAppUserEMail(appUser.email.toLowerCase());
      if (userExists) {
        valid = false;
        newErrors.email =
          (fields.errorMailExists && fields.errorMailExists[language]) ||
          "Diese E-Mail-Adresse ist bereits registriert";
      }
    }

    if (!appUser.name) {
      valid = false;
      newErrors.name =
        (fields.errorName && fields.errorName[language]) ||
        "Bitte, geben Sie einen gültigen Namen ein";
    }

    if (!appUser.surname) {
      valid = false;
      newErrors.surname =
        (fields.errorName && fields.errorName[language]) ||
        "Bitte, geben Sie einen gültigen Namen ein";
    }

    setError({ ...newErrors });

    return valid;
  };

  const handleSignup = async (event: FormEvent) => {
    event.preventDefault();
    setRegistering(true);
    const valid = await validate();
    if (!valid) {
      setRegistering(false);
      return;
    }

    try {
      const newUser: AppUser = { ...appUser };
      newUser.email = appUser.email.toLowerCase();

      const newId = await registerUser(newUser);
      // Signed in
      if (newId) {
        console.log("user signed up");
        sendVerificationEmail(newUser.email, language);
        setActiveStep("registered");
      } else {
        console.log("user could not be signed up");
        setError({
          ...error,
          error:
            (fields.errorCouldNotRegister && fields.errorCouldNotRegister[language]) ||
            "Could not sign up! Please try again later",
        });
      }
    } catch (err: any) {
      setError({
        ...error,
        error:
          (fields.errorCouldNotRegister && fields.errorCouldNotRegister[language]) ||
          "Could not sign up! Please try again later",
      });
      // Handle errors here
    } finally {
      setRegistering(false);
    }
  };

  const getSignupPage = () => {
    return (
      <React.Fragment>
        <TextField
          type="text"
          placeholder={fields.labelName && fields.labelName[language]}
          fullWidth
          name="name"
          variant="outlined"
          size="small"
          value={appUser.name}
          onChange={handleInputChange}
          error={!!error.name}
          helperText={error.name}
          margin="dense"
          required
          autoFocus
        />
        <TextField
          type="text"
          placeholder={fields.labelSurname && fields.labelSurname[language]}
          fullWidth
          name="surname"
          variant="outlined"
          size="small"
          value={appUser.surname}
          onChange={handleInputChange}
          error={!!error.surname}
          helperText={error.surname}
          margin="dense"
          required
          autoFocus
        />
        <TextField
          type="email"
          placeholder="Email"
          fullWidth
          name="email"
          variant="outlined"
          size="small"
          value={appUser.email}
          onChange={handleInputChange}
          error={!!error.email}
          helperText={error.email}
          margin="dense"
          required
          autoFocus
        />
        <FormControl sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <FormControlLabel
            control={<Checkbox onChange={handleTermsChange} checked={termsAccepted} name="terms" />}
            label={fields.terms && fields.terms[language]}
            sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.875rem" } }} // Adjust the font size here
          />
        </FormControl>

        {error.error && (
          <Typography variant="body2" color="error" sx={{ marginTop: "1rem" }}>
            {error.error}
          </Typography>
        )}

        <StyledButtonInverted
          variant="contained"
          color="primary"
          type="submit"
          className="button-block"
          fullWidth
          disabled={!termsAccepted || registering}
          onClick={handleSignup}
        >
          {(fields.button && fields.button[language]) || "Sign up"}
        </StyledButtonInverted>
        <Typography align="center" variant="body2" sx={{ marginTop: "2rem" }}>
          {fields.labelAlreadyHaveAccount && fields.labelAlreadyHaveAccount[language]}{" "}
          <Link variant="body2" href="/login">
            Login
          </Link>
        </Typography>
      </React.Fragment>
    );
  };

  const getLoadingScreen = () => {
    console.log("activeStep: ", activeStep);

    return <LoadingWrapper title="Loading Application..." />;
  };

  if (!fields || isLoading) {
    console.log("Sign in loading...");
    console.log("fields: ", !!fields);
    console.log("isLoading: ", isLoading);
    return getLoadingScreen();
  }

  return (
    <Box maxWidth="500px" mx={"auto"}>
      {activeStep === "loading" && getLoadingScreen()}
      {activeStep === "signup" && (
        <PageWrapper>
          <Box display="flex" justifyContent="space-between">
            <PageTitle>{fields.title && fields.title[language]}</PageTitle>
          </Box>
          <Spacer i={2} />
          <Typography component={"div"}>{"Sign up for your value exploration"}</Typography>
          {getSignupPage()}
        </PageWrapper>
      )}
      {activeStep === "registered" && (
        <PageWrapper>
          <Typography component="div">
            {getDescription(fields.registeredDescription && fields.registeredDescription[language])}
          </Typography>
        </PageWrapper>
      )}
    </Box>
  );
};

export default RegisterPage;
