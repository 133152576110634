import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../provider/language-provider";
import { ValueContext } from "../provider/value-provider";
import { TypeReduce } from "../types/contentful";
import useContentful from "../hooks/useContentful";
import { Value, Values } from "../types/values";
import { DEFAULT_LANGUAGE } from "../types/languages";
import { cloneValues } from "../utils/common";
import env from "../env-config";
import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import { StyledButton, StyledButtonValue, StyledTypographyValue } from "../components/customs";
import Spacer from "../components/spacer";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgressWithLabel from "../components/linear-progress-with-label";
import LoadingWrapper from "./pageLoading";
import PageWrapper from "../components/application-wrapper";
import CustomStepper from "../components/custom-stepper";
import { getDescription } from "../components/richt-text-contentful";

const ReducePage = () => {
  const [reduceCount, setReduceCount] = useState(0);
  const [reduceValues, setReduceValues] = useState<Values>([]);
  const [index, setIndex] = useState(-1);

  const fields = (useContentful("reduce") as TypeReduce<"WITH_ALL_LOCALES">).fields;

  const navigate = useNavigate();
  const { language } = useContext(LanguageContext)!;
  const { values, setValues } = useContext(ValueContext)!;

  const progress = (index / Math.max(reduceCount, 1)) * 100;
  const value = index > -1 ? reduceValues[index] : null;

  const valueTranslation =
    index > -1 && reduceValues.length > index
      ? reduceValues[index].translations[language]
        ? reduceValues[index].translations[language]
        : reduceValues[index].translations[DEFAULT_LANGUAGE]
      : { value: "", warning: "", info: "" };

  // ----------- react hooks -----------
  useEffect(() => {
    console.log("values have changed. resetting index");
    setIndex(-1);
  }, [values]);

  // ----------- Component functions -----------

  function startReduce() {
    console.log("preparing reduced values...");
    const selectedTrue = values.filter((e) => e.selected === true);

    const newValues = selectedTrue.map((value) => value.clone());
    console.log("reduceValues: ", newValues);
    setReduceValues(newValues);
    setReduceCount(newValues.length);
    setIndex(0);
  }

  function handleReduceUpdate(value: Value, selected: boolean) {
    const newValues = cloneValues(reduceValues);
    const index = newValues.findIndex((e) => e.id === value.id);

    newValues[index].selected = selected;
    setReduceValues(newValues);
  }

  function handleReduceEnd() {
    console.log("ending reduce");
    const newValues: Values = cloneValues(values);

    reduceValues.forEach((value) => {
      const index = newValues.findIndex((e) => e.id === value.id);
      newValues[index] = value.clone();
    });

    const selectedTrue = newValues.filter((e) => e.selected === true);
    console.log("left selected true: ", selectedTrue.length);

    if (selectedTrue.length > env.adjusted.max) {
      setValues(newValues);
      navigate(`/adjust`);
      return;
    }

    newValues.forEach((value) => {
      if (value.selected) {
        value.adjusted = true;
      }
    });
    setValues(newValues);
    navigate(`/focus`);
  }

  function handleYes(value: Value | null) {
    if (value) {
      handleReduceUpdate(value, true);

      goNext();
    }
  }

  function handleNo(value: Value | null) {
    if (value) {
      handleReduceUpdate(value, false);
      goNext();
    }
  }

  function goNext() {
    console.log("go next. current index: ", index);
    if (index < reduceValues.length) {
      //   const analytics = getAnalytics();
      //   logEvent(analytics, "screen_view", {
      //     screen_name: `reduce_index_${index}`,
      //   });
      if (index === reduceValues.length - 1) {
        handleReduceEnd();
      } else {
        setIndex(index + 1);
      }
    }
  }

  function displayExplanation() {
    return (
      <>
        <Typography component="div">
          {getDescription(fields?.description ? fields.description[language] : null)}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <StyledButton variant="contained" color="primary" onClick={startReduce}>
            {fields.buttonStart && fields.buttonStart[language]}
          </StyledButton>
        </Box>
      </>
    );
  }

  function displayReducer() {
    return (
      <>
        <Typography variant="h6">{fields.title && fields.title[language]}</Typography>
        <Spacer i={2} />
        <StyledTypographyValue align="center">{valueTranslation.value}</StyledTypographyValue>
        <Spacer />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} container alignItems="center">
            <StyledButtonValue
              variant="contained"
              fullWidth
              size="large"
              color="primary"
              onClick={() => handleYes(value)}
              startIcon={<CheckIcon />}
            >
              {fields.buttonYes && fields.buttonYes[language]}
            </StyledButtonValue>
          </Grid>

          <Grid item xs={12} sm={6} container alignItems="center">
            <StyledButtonValue
              variant="contained"
              fullWidth
              size="large"
              color="primary"
              startIcon={<CloseIcon />}
              onClick={() => handleNo(value)}
            >
              {fields.buttonNo && fields.buttonNo[language]}
            </StyledButtonValue>
          </Grid>
        </Grid>
        {valueTranslation.warning && (
          <>
            <Alert severity="warning">{valueTranslation.warning}</Alert>
          </>
        )}
        {/* {value.info && (
          <>
            <Alert severity="info">
              <AlertTitle>{fields.infoCaption}</AlertTitle>
              {value.info}
            </Alert>
          </>
        )} */}
        <Spacer i={2} />
        <LinearProgressWithLabel value={progress} />
        <Spacer />
        {index > 0 && (
          <Button variant="outlined" onClick={() => setIndex(index - 1)}>
            {fields.buttonBack && fields.buttonBack[language]}
          </Button>
        )}
      </>
    );
  }

  if (!fields) {
    return <LoadingWrapper />;
  }

  return (
    <PageWrapper>
      <CustomStepper activeStep={0} />
      {index === -1 && displayExplanation()}
      {index > -1 && displayReducer()}
    </PageWrapper>
  );
};

export default ReducePage;
