import { Paper, PaperProps, styled, useMediaQuery, useTheme, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { borderRadiusSmall } from "../theme";

interface PageWrapperProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  minheight?: string;
}

interface StyledPaperProps extends PaperProps {
  minheight?: string;
}

interface PageTitleProps {
  children: React.ReactNode;
}

const StyledPaper = styled(Paper)<StyledPaperProps>(({ theme, minheight }) => ({
  // Define your default styles here
  minWidth: "320px",
  maxWidth: "1024px",
  boxShadow: "none",
  margin: theme.spacing(1, "auto"),
  padding: theme.spacing(1),
  minHeight: "300px",
  borderRadius: borderRadiusSmall,
  border: "1px solid",
  borderColor: "black",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(1, "auto"),
    padding: theme.spacing(2),
    minHeight: minheight || "500px",
  },
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(1, 2),
    width: "calc(100% - 32px)",
  },
}));

const PageWrapper: React.FC<PageWrapperProps> = ({ children, sx, minheight }) => {
  return (
    <StyledPaper minheight={minheight} sx={sx}>
      {children}
    </StyledPaper>
  );
};

export default PageWrapper;

export const PageTitle = (props: PageTitleProps) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Typography variant={isXs ? "h4" : "h3"} align="center">
      {props.children}
    </Typography>
  );
};
