import { useContext, useEffect } from "react";
import { AuthContext } from "../provider/auth-provider";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingWrapper from "../pages/pageLoading";
import NotAuthorized from "../pages/pageNotAuthorized";

interface ProtectedRouteProps {
  children: JSX.Element;
  onlyAdmin?: boolean;
}

export const ProtectedRoute = ({ children, onlyAdmin = false }: ProtectedRouteProps) => {
  const { currentUser, isLoading } = useContext(AuthContext)!;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && !(currentUser && currentUser.authUser)) {
      // If user is not logged in, redirect to login page
      console.log("not logged in -> redirecting to the login page");
      navigate("/login", location);
    }
  });

  useEffect(() => {
    // Ensure loading is complete and user exists before checking role
    if (!isLoading) {
      if (currentUser && currentUser.authUser && currentUser.appUser) {
        if (onlyAdmin && !currentUser.appUser.isAdmin) {
          navigate("/");
        }
      } else {
        // Redirect to login if not logged in
        console.log("not logged in -> redirecting to the login page");
        navigate("/login", { state: { from: location } }); // Pass the current location in state to enable redirecting back after login
      }
    }
  }, [isLoading, currentUser, onlyAdmin, navigate, location]);

  const getPage = () => {
    if (!isLoading) {
      if (currentUser && currentUser.authUser && currentUser.appUser) {
        if (onlyAdmin && !currentUser.appUser.isAdmin) {
          return <NotAuthorized />;
        }
        return children;
      }
      return <NotAuthorized />;
    } else {
      return <LoadingWrapper title="Loading application..." />;
    }
  };

  return getPage();
};
