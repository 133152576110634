import { collection, getDocs, query, where } from "firebase/firestore";
import db, { functions } from "../types/firebase";
import { httpsCallable } from "firebase/functions";
import env from "../env-config";

interface RegisterUserResult {
  data: {
    status: string;
    error?: string;
    id?: string;
  };
}

export const isUserRegistered = async (email: string) => {
  try {
    const docRef = query(collection(db, "users"), where("email", "==", email.toLowerCase()));
    const docSnap = await getDocs(docRef);
    console.log("users found: ", docSnap.docs);
    return docSnap.docs.length > 0;
  } catch (e) {
    console.log("Error getting  document:", e);
    return false;
  }
};

export const sendVerificationEmail = async (email: string, language: string) => {
  email = email.trim();
  console.log("check if registered: " + email);

  language = language ? language : "de";
  const locale = language === "de" ? "de-DE" : "en-US";
  const date = new Date();
  const loginTime = `${date.toLocaleDateString(locale, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })} - ${date.toLocaleTimeString(locale, { hour: "2-digit", minute: "2-digit" })}`;

  const sendSignInLink = httpsCallable(functions, "api/v1/verifyMail");
  console.log("calling verifyEmail");
  const response = (await sendSignInLink({
    url: env.dev.signin_domain,
    email,
    language,
    loginTime,
  })) as RegisterUserResult;

  if (response?.data?.status === "OK") {
    localStorage.setItem("signInEmail", email);

    console.log("Email sent successfully.");
    return true;
  } else {
    console.error(["Error sending email verification."]);
    return false;
  }
};
